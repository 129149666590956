import { PageProps } from 'gatsby'
import { LayoutContext } from 'src/features/layout'
import { SummaryData } from 'src/features/summary/types'
import React, { useContext, useEffect } from 'react'
import Summary from 'src/features/summary/summary-two'
import AtlBgTwo from 'src/features/atl-bg-two'
// import ResultNav from 'src/features/result-nav'

type SummaryPageProps = {
  pageContext: { summaryData: SummaryData }
} & PageProps

const StaticSummary = ({ pageContext, location }: SummaryPageProps) => {
  const { setLocation } = useContext(LayoutContext)
  useEffect(() => {
    setLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AtlBgTwo />
      {/* <ResultNav {...{ location }} /> */}
      <Summary data={pageContext.summaryData} />
    </>
  )
}

export default StaticSummary
